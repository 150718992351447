html,
body {
	margin: 0;
	padding: 0;
	overflow-x: hidden;
}

h2.multiProducts {
	font-family: "roboto condensed", arial;
	text-align: center;
	background-color: #376092;
	border-bottom: 2px solid #18447a;
	color: #fff;
	font-size: 22px;
	font-weight: 400;
	padding: 2px;
	text-align: center
}

.prItemRating,
.prItemRating td {
	color: #000;
	font-size: 12px
}

.prItemRating,
.prItemRating a:active,
.prItemRating a:hover,
.prItemRating a:link,
.prItemRating a:visited,
.prItemRating td,
.prItemRatingCat a:active,
.prItemRatingCat a:hover,
.prItemRatingCat a:link,
.prItemRatingCat a:visited,
.prItemReviewLinkNoRating a:hover {
	font-family: arial, verdana, sans-serif;
	font-weight: 500
}

.reviewFormStaticInputHeadline:focus,
.reviewFormStaticInputInfo:focus,
.reviewFormStaticTextarea:focus,
.reviewFormStaticTextareaReview:focus {
	background-color: #fdf5cf
}

#homeFooter,
#leftNavCell,
h1#cat-title-2015,
h2#cat-title-2015 {
	box-sizing: border-box
}

a img {
	border: 0
}

.reviewFormStaticItem,
.reviewFormStaticItem img {
	border: 1px solid #c8c8c8
}

.prShow {
	display: block
}

.prHide {
	display: none
}

.prItemRating img {
	vertical-align: middle;
	margin-right: 6px
}

.prItemRating a:active,
.prItemRating a:link,
.prItemRating a:visited {
	color: #4975a2;
	font-size: 12px;
	text-decoration: underline
}

.prItemRating a:hover {
	color: #000;
	font-size: 12px;
	text-decoration: underline
}

.prItemReviewLinkNoRating a:active,
.prItemReviewLinkNoRating a:link,
.prItemReviewLinkNoRating a:visited {
	color: #4975a2;
	font-family: arial, verdana, sans-serif;
	font-size: 11px;
	font-weight: 500;
	text-decoration: underline
}

.prItemReviewLinkNoRating a:hover {
	color: #666;
	font-size: 11px;
	text-decoration: underline
}

.prItemRatingCat {
	line-height: 15px
}

.prItemRatingCat span {
	color: #999
}

.prItemRatingCat img {
	vertical-align: middle;
	margin-left: 4px
}

.prItemRatingCat a:active,
.prItemRatingCat a:link,
.prItemRatingCat a:visited {
	color: #999;
	font-size: 10px;
	text-decoration: none
}

.prItemRatingCat a:hover {
	color: #000;
	font-size: 10px;
	text-decoration: underline
}

.reviewFormStaticItem {
	background-image: url(http://myaccount.trophycentral.com/mod_productReviews/skins/trophycentral/images/bgReviewFormMed.gif);
	background-repeat: repeat-x;
	background-position: left bottom;
	padding: 6px
}

.reviewFormStaticItem td {
	padding: 10px
}

.reviewFormStatic h2,
.reviewFormStatic h3 {
	font-family: arial, verdana, sans-serif;
	padding: 0
}

.reviewFormStaticItemName a:active,
.reviewFormStaticItemName a:link,
.reviewFormStaticItemName a:visited {
	color: #4975a2;
	font-family: arial, verdana, sans-serif;
	font-size: 12px;
	font-weight: 700;
	text-decoration: none
}

.reviewFormStaticItemName a:hover {
	color: #666;
	font-family: arial, verdana, sans-serif;
	font-size: 12px;
	font-weight: 700;
	text-decoration: none
}

.reviewFormStatic {
	width: 650px
}

.reviewFormStatic h2 {
	color: #333;
	font-size: 18px;
	margin-top: 0;
	margin-bottom: 6px
}

.reviewFormStatic h3 {
	color: #333;
	font-size: 13px;
	margin-top: 14px;
	margin-bottom: 3px;
	margin-left: 6px
}

.reviewFormStaticEntryBig,
.reviewFormStaticEntryMed,
.reviewFormStaticEntrySm {
	border: 1px solid #c8c8c8;
	padding: 6px;
	background-repeat: repeat-x;
	background-position: left bottom
}

.reviewFormStatic span {
	color: #c80000
}

.reviewFormStatic i {
	color: #bbb;
	font-size: 10px;
	font-weight: 700;
	font-family: arial, verdana, sans-serif
}

.reviewFormStaticGrayText,
.reviewFormStaticGrayTextSm {
	color: #888;
	font-weight: 500;
	font-family: arial, verdana, sans-serif
}

#reviewFormStaticRatingStars img {
	float: left;
	display: inline;
	margin-right: 3px;
	cursor: hand;
	cursor: pointer
}

.reviewFormStaticEntrySm {
	background-image: url(http://myaccount.trophycentral.com/mod_productReviews/skins/trophycentral/images/bgReviewFormSm.gif)
}

.reviewFormStaticEntryMed {
	background-image: url(http://myaccount.trophycentral.com/mod_productReviews/skins/trophycentral/images/bgReviewFormMed.gif)
}

.reviewFormStaticEntryBig {
	background-image: url(http://myaccount.trophycentral.com/mod_productReviews/skins/trophycentral/images/bgReviewFormBig.gif)
}

.reviewFormStaticGrayText {
	font-size: 11px
}

.reviewFormStaticGrayTextSm {
	font-size: 10px
}

.reviewFormStaticInputHeadline {
	color: #333;
	font-size: 16px;
	font-weight: 700;
	font-family: arial, verdana, sans-serif;
	height: 34px;
	line-height: 34px;
	border: 1px solid #c8c8c8;
	width: 630px
}

.reviewFormStaticInputInfo,
.reviewFormStaticTextarea,
.reviewFormStaticTextareaReview {
	color: #333;
	font-size: 12px;
	border: 1px solid #c8c8c8
}

.reviewFormStaticTextarea {
	font-family: arial, verdana, sans-serif;
	width: 300px
}

.reviewFormStaticInputInfo {
	font-family: arial, verdana, sans-serif;
	width: 184px
}

.reviewFormStaticTextareaReview {
	font-family: arial, verdana, sans-serif;
	width: 630px
}

.reviewFormStaticDislikeText,
.reviewFormStaticLikeText {
	font-size: 11px;
	font-weight: 700;
	font-family: arial, verdana, sans-serif
}

.reviewFormStaticLikeText {
	color: #888
}

.reviewFormStaticLikeText b {
	color: #009600
}

.reviewFormStaticLikeText img {
	display: inline;
	vertical-align: middle
}

.reviewFormStaticDislikeText {
	color: #888
}

.reviewFormStaticDislikeText b {
	color: #c80000
}

.reviewFormStaticDislikeText img {
	display: inline;
	vertical-align: middle
}

.reviewFormStaticRecommend table {
	margin-left: 8px
}

.reviewFormStaticRecommend th {
	color: #888;
	font-size: 12px;
	font-weight: 700;
	font-family: arial, verdana, sans-serif
}

.reviewFormStaticYourInfo table {
	margin: 6px
}

.reviewFormStaticYourInfo th {
	color: #000;
	font-size: 11px;
	font-weight: 700;
	font-family: arial, verdana, sans-serif;
	text-align: left
}

.reviewFormStaticInput {
	background-color: #fff
}

.reviewFormStaticInputHighlight {
	background-color: #317082
}

.prOverallRating {
	margin-bottom: 18px;
	border: 1px solid #999
}

.prOverallRating table {
	margin-top: 8px;
	margin-left: 8px;
	margin-bottom: 10px
}

.prOverallRating td {
	padding-right: 10px;
	color: #000;
	font-family: arial, verdana, sans-serif;
	font-size: 12px;
	font-weight: 500
}

.prOverallRatingTitle {
	background-image: url(http://myaccount.trophycentral.com/mod_productReviews/skins/trophycentral/images/prTitleBg.gif);
	background-repeat: repeat-x;
	background-position: left bottom;
	background-color: #cecece;
	padding: 6px;
	border-bottom: 1px solid #999;
	clear: both
}

.prOverallRatingTitle h5 {
	color: #fff;
	font-family: arial, verdana, sans-serif;
	font-size: 14px;
	font-weight: 700;
	padding: 0;
	margin: 0
}

.prFirstToReview,
.prFirstToReview a:hover,
.prListWrapper {
	font-family: arial, verdana, sans-serif;
	font-size: 12px
}

.prButItemReview {
	margin-left: 30px
}

.prFirstToReview {
	color: #000;
	font-weight: 500
}

.prFirstToReview a:active,
.prFirstToReview a:link,
.prFirstToReview a:visited {
	color: #4975a2;
	font-family: arial, verdana, sans-serif;
	font-size: 12px;
	font-weight: 700;
	text-decoration: none
}

.prFirstToReview a:hover {
	color: #666;
	font-weight: 700;
	text-decoration: none
}

.prListWrapper {
	padding-top: 8px;
	padding-bottom: 14px;
	margin-bottom: 8px;
	border-bottom: 1px dashed #dedede;
	color: #333;
	font-weight: 500;
	display: block;
	clear: both
}

.prListTitle {
	background-image: url(http://myaccount.trophycentral.com/mod_productReviews/skins/trophycentral/images/reviewBubbleBg.gif);
	background-repeat: repeat-x;
	background-position: left bottom;
	background-color: #fff;
	border: 1px solid #d5d5d5;
	padding: 5px
}

.prListTitle h5,
.prListTitle h6 {
	padding: 0;
	font-family: arial, verdana, sans-serif;
	font-weight: 700
}

.prListTitle div.clear {
	clear: both;
	font-size: 0;
	height: 0;
	overflow: hidden
}

.prVerifiedBuyer {
	float: right;
	margin-top: 4px
}

.prListTitle h5 {
	color: #333;
	font-size: 13px;
	margin: 0 0 6px;
	text-transform: capitalize
}

.prListTitle h6 {
	margin: 0;
	line-height: 20px;
	color: #888;
	font-size: 10px
}

#jquery-lightbox,
#lightbox-loading {
	line-height: 0;
	text-align: center;
	position: absolute;
	left: 0
}

.prListTitle h6 span {
	color: #333;
	text-transform: capitalize
}

.prListTitle img {
	margin-right: 10px;
	vertical-align: middle
}

.prListBubbleArr {
	display: block
}

.prListPros {
	float: left;
	width: 230px;
	padding-right: 20px
}

.prListPros h5 {
	color: #006400;
	font-family: arial, verdana, sans-serif;
	font-size: 12px;
	font-weight: 700;
	padding: 0;
	margin: 15px 0 4px
}

.prListCons {
	float: right;
	width: 250px
}

.prListCons h5 {
	color: #7d0000;
	font-family: arial, verdana, sans-serif;
	font-size: 12px;
	font-weight: 700;
	padding: 0;
	margin: 15px 0 4px
}

.prListRecommend h5,
.prMerchantResponse h5 {
	color: #333;
	font-family: arial, verdana, sans-serif;
	font-size: 12px;
	font-weight: 700
}

.prListRecommend {
	clear: both;
	padding-top: 10px
}

.prListRecommend h5 {
	padding: 0;
	margin: 0
}

.prReviewPhotos {
	clear: both;
	padding-top: 10px;
	margin-bottom: 6px
}

.prReviewPhotos img {
	margin-right: 5px
}

.prMerchantResponse {
	margin-top: 10px;
	padding: 10px;
	background-color: #fffde5
}

.prMerchantResponse h5 {
	padding: 0;
	margin: 0 0 6px;
	display: block
}

#pr {
	display: none
}

.prProductReviewsDisplay {
	padding-top: 17px
}

#jquery-overlay {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 90;
	width: 100%;
	height: 500px
}

#jquery-lightbox {
	top: 0;
	width: 100%;
	z-index: 30000
}

#jquery-lightbox a img {
	border: none
}

#lightbox-container-image-box {
	position: relative;
	background-color: #fff;
	width: 250px;
	height: 250px;
	margin: 0 auto
}

#lightbox-container-image {
	padding: 10px
}

#lightbox-loading {
	top: 40%;
	height: 25%;
	width: 100%
}

#lightbox-nav {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 10
}

#lightbox-container-image-box>#lightbox-nav {
	left: 0
}

#lightbox-nav a {
	outline: 0
}

#lightbox-nav-btnNext,
#lightbox-nav-btnPrev {
	width: 49%;
	height: 100%;
	zoom: 1;
	display: block
}

#lightbox-nav-btnPrev {
	left: 0;
	float: left
}

#lightbox-nav-btnNext {
	right: 0;
	float: right
}

#lightbox-container-image-data-box {
	font: 10px Verdana, Helvetica, sans-serif;
	background-color: #fff;
	margin: 0 auto;
	line-height: 1.4em;
	overflow: auto;
	width: 100%;
	padding: 0 10px
}

#multiadd2 input,
#multiadd2 select,
#multiadd2 td {
	font-size: 10px
}

#toplinks,
.add2cartbutton {
	font-family: arial, helvetica, sans-serif
}

#lightbox-image-details-caption,
.add2cartbutton,
h1,
h2#cat-title-2015,
shipping-heading,
shipping-title {
	font-weight: 700
}

#lightbox-container-image-data {
	padding: 0 10px;
	color: #666
}

#lightbox-container-image-data #lightbox-image-details {
	width: 70%;
	float: left;
	text-align: left
}

#lightbox-image-details-currentNumber {
	display: block;
	clear: left;
	padding-bottom: 1em
}

#lightbox-secNav-btnClose {
	width: 66px;
	float: right;
	padding-bottom: .7em
}

.tabcell0 {
	background-color: #0076b5
}

.tabcellSelect0 {
	background-color: #001f77
}

.tabcell1 {
	background-color: #0076b5
}

.tabcellSelect1 {
	background-color: #001f77
}

.tabcell2 {
	background-color: #0076b5
}

.tabcellSelect2 {
	background-color: #001f77
}

.tabcell3 {
	background-color: #0076b5
}

.tabcellSelect3 {
	background-color: #001f77
}

.tabcell4 {
	background-color: #0076b5
}

.tabcellSelect4 {
	background-color: #001f77
}

.tabcell5 {
	background-color: #0076b5
}

.tabcellSelect5 {
	background-color: #001f77
}

.tabcell6 {
	background-color: #0076b5
}

.tabcellSelect6 {
	background-color: #001f77
}

.tabcell7 {
	background-color: #0076b5
}

.tabcellSelect7 {
	background-color: #001f77
}

.tabcell8 {
	background-color: #0076b5
}

.tabcellSelect8 {
	background-color: #001f77
}

.tabcell9 {
	background-color: #0076b5
}

.tabcellSelect9 {
	background-color: #001f77
}

.tabcell10 {
	background-color: #0076b5
}

.tabcellSelect10 {
	background-color: #001f77
}

.tabcell11 {
	background-color: #0076b5
}

.tabcellSelect11 {
	background-color: #001f77
}

.tabcell12 {
	background-color: #0076b5
}

.tabcellSelect12 {
	background-color: #001f77
}

.tabcell13 {
	background-color: #0076b5
}

.tabcellSelect13 {
	background-color: #001f77
}

.tabcell14 {
	background-color: #0076b5
}

.tabcellSelect14 {
	background-color: #001f77
}

#multiadd2 td {
	border-left: 1px solid silver
}

.add2cartbutton {
	font-size: 11px;
	color: #fff;
	background-color: #165189;
	border: 1px solid #fff
}

.add2cartborder {
	border: 2px solid #165189;
	margin-left: 5px
}

#toplinks {
	font-size: 12px;
	color: #00f;
	white-space: nowrap
}

#toplinks a:link,
#toplinks a:visited {
	text-decoration: none
}

#toplinks a:hover {
	text-decoration: underline
}

#navbar .subsections a:link,
#navbar .subsections a:visited,
#navbar label a:link,
#navbar label a:visited,
#toptabs a:link,
#toptabs a:visited {
	display: block;
	font-family: arial, helvetica, sans-serif;
	text-decoration: none
}

#toptabs td {
	font-size: 10px
}

#toptabs a:link,
#toptabs a:visited {
	padding-left: 3px;
	padding-right: 3px;
	color: #fff
}

#navbarcell {
	padding: 4px;
	border-right: 1px solid gray;
	border-left: 1px solid gray;
	border-bottom: 1px solid gray
}

.hr {
	border-top: 0 dotted gray
}

#navbar label a:link,
#navbar label a:visited {
	font-size: 12px;
	color: #001f77;
	font-weight: 700;
	margin-top: 4px
}

#navbar label a:hover {
	background-color: #0076b5;
	color: #fff
}

#navbar .subsections a:link,
#navbar .subsections a:visited {
	font-size: 11px;
	color: #333;
	margin-left: 10px
}

#orderDiv,
.nxt_refines_surround br,
.nxtpTab {
	display: none
}

h1,
h2#cat-title-2015,
shipping-heading,
shipping-info,
shipping-title {
	font-family: Arial, Helvetica, sans-serif;
	color: #000;
	margin: 1px
}

#navbar .subsections a:hover {
	text-decoration: underline
}

h1,
h2#cat-title-2015 {
	font-size: 15px;
	line-height: 18px
}

shipping-title {
	font-size: 10px;
	line-height: 10px
}

shipping-heading {
	font-size: 15px;
	line-height: 15px
}

shipping-info {
	font-size: 10px;
	font-weight: 400;
	line-height: 10px
}

#topzform {
	margin: 0;
	padding: 0
}

#topsearchform input {
	height: auto
}

#topsearchQuery {
	font-weight: 700;
	text-align: center
}

#add2cartbutton,
#engraveButton {
	font-size: 13px
}

#orderBlock {
	padding: 15px;
	border: 1px solid silver;
	width: 400px
}

#homeTopBox {
	position: relative;
	width: 824px;
	background: url(https://turbifycdn.com/ty/cdn/sportsawards/hometopbox-back.jpg) bottom left no-repeat;
	height: 375px;
	min-height: 375px
}

#htb-top {
	position: absolute;
	top: 0;
	left: 0
}

#htb-bottom {
	position: absolute;
	bottom: 0;
	left: 0
}

#htb-words {
	position: absolute;
	top: 18px;
	left: 2px
}

#homeTopBox h2 {
	position: absolute;
	top: 10px;
	left: 0;
	width: 824px;
	text-align: center;
	margin: 0;
	font: 700 18px tahoma;
	color: #d67b0c
}

.sprite-bl,
.sprite-br {
	width: 22px;
	height: 22px
}

#htb-facebook {
	position: absolute;
	top: 8px;
	right: 8px;
	border-width: 0
}

#htb-twitter {
	position: absolute;
	top: 8px;
	right: 38px;
	border: 0
}

#homeProdFinder {
	position: absolute;
	top: 36px;
	left: 513px
}

#htb-specials {
	border-width: 0;
	margin-left: 0
}

#htb-spotlight {
	border-width: 0;
	margin-left: 20px
}

.ytImgRotatorControls a {
	padding: 1px 3px !important
}

.ytImgRotatorControls {
	margin: 5px 0 10px !important
}

#tabsDiv {
	position: relative;
	z-index: 10000
}

.nxtpTab {
	position: absolute;
	background-color: #fff
}

.nxt_refines_surround a {
	color: #00f !important;
	white-space: nowrap
}

.nxt_refines_surround {
	font-family: arial;
	font-size: 11px
}

iframe.grelated-iframe {
	left: -99999px !important;
	display: none !important
}

.sp1 {
	background: url(https://turbifycdn.com/ty/cdn/sportsawards/csg-4e7bfa7cef0ea.png) top left no-repeat;
	border: 0
}

.sprite-bl {
	background-position: 0 0
}

.sprite-br {
	background-position: 0 -32px
}

.sprite-tab-left {
	background-position: 0 -64px;
	width: 7px;
	height: 6px
}

.sprite-tab-right {
	background-position: 0 -80px;
	width: 7px;
	height: 6px
}

.sprite-topsearch-go {
	background-position: 0 -96px;
	width: 51px;
	height: 54px
}

#ys_relatedItems {
	clear: before;
	border: 1px solid #000;
	font-family: verdana;
	margin: 15px 0
}

.ystore-cross-sell-title {
	width: 333px;
	height: 46px;
	color: transparent;
	background-image: url(https://turbifycdn.com/ty/cdn/sportsawards/y-cross-sell-header.gif)
}

.ystore-cross-sell-cell {
	text-align: center !important
}

.ystore-cross-sell-product-caption a {
	font-size: 12px;
	font-weight: 400;
	color: #000
}

.ystore-cross-sell-product-image {
	border-width: 0;
	display: inline
}

.ystore-cross-sell-product-price {
	color: red !important;
	font-weight: 400;
	margin: 0;
	font-size: 11px
}

.ystore-cross-sell-product-name,
.ystore-cross-sell-product-reg-price {
	margin: 2px;
	font-size: 11px
}

.ystore-cross-sell-table {
	width: 100%
}

#modalOverlay {
	background-color: #000;
	cursor: wait
}

#modalContainer {
	height: 400px;
	width: 680px;
	left: 50%;
	top: 15%;
	margin-left: -300px;
	background-color: #fff;
	border: 3px solid #ccc;
	z-index: 15000 !important
}

#modalContainer a.modalCloseImg {
	background: url(https://turbifycdn.com/ty/cdn/sportsawards/x.png) no-repeat;
	width: 25px;
	height: 29px;
	display: inline;
	z-index: 3200;
	position: absolute;
	top: -14px;
	right: -18px;
	cursor: pointer
}

#homeTopBoxFull,
#modal {
	position: relative
}

#modalContainer {
	background: #fff
}

#modalContainer * {
	font-family: arial, helvetica, sans-serif;
	font-size: 12px
}

#modalContainer h1 {
	font-size: 22px;
	color: grey
}

#productFinderText {
	margin-top: 5px
}

.persItemHead {
	background-color: #369;
	padding: 3px;
	font-weight: 700;
	color: #fff
}

#engraving,
#engraving td {
	font-family: arial, helvetica, sans-serif;
	font-size: 10pt
}

#recenthistory,
#recenthistory td,
#testimonials {
	font-size: 11px;
	font-family: arial, helvetica, sans-serif
}

#recenthistory label {
	display: block;
	background-color: #369;
	padding: 2px;
	color: #fff;
	font-weight: 700
}

#testimonials {
	background-color: #ffc;
	padding: 3px
}

#prodPrice {
	font-family: arial, helvetica, sans-serif;
	font-size: 18px;
	color: navy;
	margin-bottom: .5em
}

#configContainer h2 {
	border-top: 1px solid silver;
	font-family: arial;
	font-size: 10pt
}

.configContainerMain div {
	float: left
}

.configContainerMain {
	white-space: nowrap;
	width: 440px;
	overflow-x: auto;
	margin-bottom: 1em
}

.configContainer {
	height: 100px;
	white-space: nowrap;
	width: 10000px
}

.configContainer a.selected img {
	border: 2px solid #00f
}

#customizeResults {
	width: 200px;
	float: right;
	font-family: arial;
	font-size: 11px
}

#customizeResults div {
	margin-bottom: 4px
}

.selectedName b {
	font-weight: 700;
	color: #E8802B
}

.selectedName small {
	font-size: 11px
}

#bsellHead,
#cat-title {
	background-color: #369;
	color: #fff;
	display: inline-block;
	padding: 5px;
	-moz-border-radius: 5px;
	border-radius: 7px
}

#bsellHead {
	display: block;
	font-family: arial;
	font-weight: 700;
	font-size: 15px
}

.multiAbstracts,
h2.multiSection {
	font-family: "roboto condensed", arial;
	text-align: center
}

h2.multiSection {
	background-color: #74bc3a;
	border-bottom: 2px solid #61a629;
	color: #fff;
	font-size: 22px;
	font-weight: 400;
	padding: 2px;
	text-align: center
}

h2.multiSection a:link,
h2.multiSection a:visited {
	color: #fff;
	text-decoration: none;
	text-align: center
}

.multiAbstracts {
	font-size: 12px;
	color: #7e7f84;
	text-align: center
}

.multiAbstracts a {
	text-decoration: none;
	color: #559620;
	font-size: 19px
}

.topMenuSub {
	background-color: #fff;
	border: 1px solid #001c77;
	padding: 10px 5px;
	width: 175px
}

.topMenuSub a {
	font: 400 12px arial;
	color: #001c77 !important;
	display: block;
	text-align: left;
	padding: 2px 5px
}

#toplinks .bbbcart,
#toplinks .clickToTalk,
#toplinks .links {
	text-align: center;
	float: right
}

.topMenuSub a:hover {
	background-color: #e0e0e0
}

#toptabs.newTopNav {
	margin-top: 10px;
	width: 981px
}

#toptabs.newTopNav .tabCell0,
#toptabs.newTopNav .tabCell1,
#toptabs.newTopNav .tabCell10,
#toptabs.newTopNav .tabCell11,
#toptabs.newTopNav .tabCell12,
#toptabs.newTopNav .tabCell2,
#toptabs.newTopNav .tabCell3,
#toptabs.newTopNav .tabCell4,
#toptabs.newTopNav .tabCell5,
#toptabs.newTopNav .tabCell6,
#toptabs.newTopNav .tabCell7,
#toptabs.newTopNav .tabCell8,
#toptabs.newTopNav .tabCell9 {
	background-color: #fff;
	color: #369 !important;
	border-right: solid 1px #369 !important
}

#toptabs.newTopNav .tabCell0 a,
#toptabs.newTopNav .tabCell1 a,
#toptabs.newTopNav .tabCell10 a,
#toptabs.newTopNav .tabCell11 a,
#toptabs.newTopNav .tabCell12 a,
#toptabs.newTopNav .tabCell2 a,
#toptabs.newTopNav .tabCell3 a,
#toptabs.newTopNav .tabCell4 a,
#toptabs.newTopNav .tabCell5 a,
#toptabs.newTopNav .tabCell6 a,
#toptabs.newTopNav .tabCell7 a,
#toptabs.newTopNav .tabCell8 a,
#toptabs.newTopNav .tabCell9 a {
	color: #369 !important;
	font-size: 12px !important
}

#toptabs.newTopNav .tabCellSelect0,
#toptabs.newTopNav .tabCellSelect1,
#toptabs.newTopNav .tabCellSelect10,
#toptabs.newTopNav .tabCellSelect11,
#toptabs.newTopNav .tabCellSelect12,
#toptabs.newTopNav .tabCellSelect2,
#toptabs.newTopNav .tabCellSelect3,
#toptabs.newTopNav .tabCellSelect4,
#toptabs.newTopNav .tabCellSelect5,
#toptabs.newTopNav .tabCellSelect6,
#toptabs.newTopNav .tabCellSelect7,
#toptabs.newTopNav .tabCellSelect8,
#toptabs.newTopNav .tabCellSelect9 {
	border-right: solid 1px #001f77 !important;
	border-color: #001f77 !important
}

#toptabs.newTopNav .tabCellSelect0 a,
#toptabs.newTopNav .tabCellSelect1 a,
#toptabs.newTopNav .tabCellSelect10 a,
#toptabs.newTopNav .tabCellSelect11 a,
#toptabs.newTopNav .tabCellSelect12 a,
#toptabs.newTopNav .tabCellSelect2 a,
#toptabs.newTopNav .tabCellSelect3 a,
#toptabs.newTopNav .tabCellSelect4 a,
#toptabs.newTopNav .tabCellSelect5 a,
#toptabs.newTopNav .tabCellSelect6 a,
#toptabs.newTopNav .tabCellSelect7 a,
#toptabs.newTopNav .tabCellSelect8 a,
#toptabs.newTopNav .tabCellSelect9 a {
	font-size: 12px !important
}

#toptabs.newTopNav .tabCell0,
#toptabs.newTopNav .tabCellSelect0 {
	border-left: solid 1px #369 !important
}

#toplinks {
	padding-top: 6px
}

#toplinks .clickToTalk {
	width: 160px;
	height: 64px
}

#toplinks .clickToTalk a {
	display: block
}

#toplinks .clickToTalk span {
	display: block;
	font: 400 12px arial;
	color: #000;
	margin-top: 4px
}

#toplinks .links {
	width: 180px;
	height: 56px;
	padding: 4px 10px;
	white-space: wrap !important
}

#toplinks .links a.orangeLink {
	display: block;
	font: 700 15px arial;
	color: #ed6c0a
}

#toplinks .links a.blackLink {
	display: block;
	font: 700 15px arial;
	color: #000;
	white-space: wrap !important
}

#toplinks .bbbcart {
	width: 115px;
	height: 64px
}

#toplinks .bbbcart a {
	display: block;
	padding: 2px 0 5px
}

.newContentsDiv {
	width: 932px;
	margin: 0 auto !important
}

.newContentsItem {
	float: left;
	width: 240px;
	border: 1px solid #dee0e2;
	margin-right: 100px;
	background-color: #dee0e2;
	margin-bottom: 30px
}

.newContentsItem center a img.newContentsIcon {
	width: 240px;
	height: 240px
}

.newContentsItem center a img.sticker {
	width: auto;
	height: auto
}

.newContentsItemInfo {
	margin: 6px;
	background-color: #f7f7f8
}

.newContentsItemInfoInner {
	padding: 3px;
	font: 400 11px arial
}

.newContentsItemInfoInner a {
	font: 700 13px arial;
	display: block;
	color: #369;
	text-decoration: none
}

.newContentsItemInfoInner .newContentsText {
	padding: 3px 0
}

.newContentsItemInfoInner .newContentsText a {
	display: inline;
	font: 400 11px arial
}

.newContentsItemInfoInner .newContentsText .sectPr font {
	font: 700 12px arial;
	display: block;
	text-align: left;
	padding-top: 5px
}

.newContentsDiv .newContentsItem:nth-child(3n) {
	margin-right: 0
}

#homeTopBoxFull {
	width: 980px;
	background: url(https://turbifycdn.com/ty/cdn/sportsawards/hometopbox-back-full.jpg) bottom left repeat-x;
	height: 400px;
	min-height: 400px
}

#htb-top-full {
	position: absolute;
	top: 0;
	left: 0
}

#htb-bottom-full {
	position: absolute;
	bottom: 0;
	left: 0
}

#homeImgRotator {
	position: absolute;
	top: 40px;
	left: 240px
}

#homeTopBoxFull h2 {
	position: absolute;
	top: 10px;
	left: 0;
	width: 980px;
	text-align: center;
	margin: 0;
	font: 700 18px tahoma;
	color: #d67b0c
}

.tabinfomain {
	clear: both
}

h1#cat-title {
	margin: 0 !important;
	display: block;
	height: 19px !important
}

.tabinfomain .tabinfo {
	text-align: left
}

.section,
.sections {
	text-align: center;
	/* width: 1280px !important; */
	/* border: 1px solid red; */
}

.item2012 #prodtable .nameAndIcons {
	float: right !important
}

.splitSections .sectPr {
	float: none
}

.tooltip {
	cursor: help;
	text-decoration: none;
	position: relative
}

.tooltip span {
	margin-left: -999em;
	position: absolute
}

.tooltip:hover span {
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	box-shadow: 5px 5px 5px rgba(0, 0, 0, .1);
	-webkit-box-shadow: 5px 5px rgba(0, 0, 0, .1);
	-moz-box-shadow: 5px 5px rgba(0, 0, 0, .1);
	font-family: Calibri, Tahoma, Geneva, sans-serif;
	position: absolute;
	left: 2em;
	top: 2.5em;
	z-index: 99999;
	margin-left: 0;
	width: 240px
}

#cartLink,
#engraving label,
#tc2015-header,
#tc2015-logolink,
.sections .item,
h1#cat-title-2015,
h2#cat-title-2015 {
	position: relative
}

.tooltip:hover em {
	font-family: Candara, Tahoma, Geneva, sans-serif;
	font-size: 1.2em;
	font-weight: 700;
	display: block;
	padding: .2em 0 .5em
}

.classic {
	padding: .8em 1em
}

.custom {
	padding: .5em .8em .8em 2em
}

* html a:hover {
	background: 0 0
}

.classic {
	background: #FFA;
	border: 1px solid #FFAD33
}

.critical {
	background: #FCA;
	border: 1px solid #FF3334
}

.help,
.info {
	background: #9FDAEE
}

.help {
	border: 1px solid #2BB0D7
}

.info {
	border: 1px solid #009
}

.warning {
	background: #FFA;
	border: 1px solid #FFAD33
}

.section,
.sections .item {
	border: 1px solid #e0dddd;
	display: inline-block;
	zoom: 1;
	vertical-align: top;
	font-family: "Roboto Condensed"
}

#homeTopBox {
	margin-left: auto;
	margin-right: auto
}

.section {
	margin-right: 20px;
	width: 310px;
	padding-bottom: 40px
}

.sections .section {
	margin-bottom: 20px
}

.section .title a {
	color: #549520;
	font-size: 19px;
	font-weight: 400;
	text-decoration: none
}

.section .desc {
	color: #7e7f84;
	font-size: 12px;
	margin-top: 20px
}

.sections .item {
	margin-bottom: 26px;
	margin-right: 12px;
	text-align: left;
	width: 226px
}

.leftNav .item {
	margin-right: 20px
}

.sections .item .title a {
	color: #4e4f52;
	font-size: 15px;
	font-weight: 400;
	line-height: 20px;
	text-decoration: none
}

.sections .item .desc {
	color: #969595;
	font-size: 11px;
	margin-left: 20px;
	margin-top: 10px;
	line-height: 16px
}

.sections .item .textBox {
	background-color: #f8f6f6;
	border-top: 1px solid #e0dddd;
	margin-top: 26px;
	padding: 16px;
	/* min-height:156px */
}

.sections .item:nth-child(4n+1):not(.colOverride) {
	clear: left
}

.sections .item.colOverride center img {
	display: block;
	max-width: 100%;
	height: auto;
	margin: 0px auto;
}

.sections .item.colOverride .textBox {
	margin-top: 15px;
	padding: 8px;
	min-height: 0;
}

.sections .item.colOverride .desc {
	margin: 5px 0 0;
}

.sections .item.colOverride .sectPr:empty {
	margin: 0;
}

.leftNav .sections .item:nth-child(4n+1) {
	clear: none
}

.leftNav .sections .item:nth-child(3n+1) {
	clear: left
}

.sections .item .prItemRatingCat {
	margin-top: 5px;
	text-align: left;
	font-size: 11px;
	font-family: roboto
}

#comperbutton a {
	font-family: arial;
	font-size: 11px;
	text-decoration: none
}

.sections .item .sectPr {
	border-top: 1px solid #e5e2e2;
	margin-bottom: 16px;
	margin-top: 10px;
	padding-top: 10px;
	font-family: roboto;
	color: #4f8c1e;
	font-size: 15px;
	text-align: center;
}
#bodyFrame div.newContentsDiv div.sections div.item div.textBox {
	text-align: center;
}
.sections .item .shipLabel {
	display: inline-block;
	font-size: 12px;
	text-align: center;
	border: 1px solid silver;
	padding: 2px 5px;
	margin: auto;
	border-radius: 6px;
}
.sections .item .shipLabel.green {
	background-color: #4f8c1e;
  	color: white;
}
.sections .item .shipLabel.blue {
	background-color: #4f7fb0;
  	color: white;
}
.sections .item .regPr {
	color: #969595;
	font-family: roboto condensed;
	font-weight: 400;
	margin-right: .5em;
	text-decoration: line-through
}

#homeHeroWrapper {
	box-sizing: border-box;
	width: 100%;
	margin: 0 0 40px 0;
}

/* #homeHeroWrapper .slider {height:auto;}.slick-next:before,.slick-prev:before{content:" "!important}#homeHeroWrapper .slick-next,#homeHeroWrapper .slick-prev{background-color:transparent;color:#fff;width:54px;height:54px;opacity:.75}.slick-next:hover,.slick-prev:hover{opacity:1}#homeHeroWrapper .slick-prev{left:0;background-image:url(https://turbifycdn.com/ty/cdn/sportsawards/home-scroller-left.gif)}#homeHeroWrapper .slick-next{right:0;background-image:url(https://turbifycdn.com/ty/cdn/sportsawards/home-scroller-right.gif)} */
#homeHeroWrapper .slick-track:before,
.slick-track:after {
	content: "";
	display: table;
	margin: 0;
	padding: 0;
	height: 0;
	width: 0;
}

#homeHeroWrapper .slick-next,
#homeHeroWrapper .slick-prev {
	display: none;
}

#homeHeroWrapper .slick-slide img {
	display: block;
	max-width: 100%;
	height: auto;
	margin: 0px auto;
}

#homeHeroWrapper .slick-dots {
	background-color: #fff;
	margin: 0;
	text-align: center;
	bottom: -22px
}

.slick-dots li {
	position: relative;
	display: inline-block;
	height: 10px !important;
	width: 10px !important;
	margin: 0 5px;
	padding: 0;
	cursor: pointer;
}

.slick-dots li button {
	border: 0;
	display: block;
	height: 10px !important;
	width: 10px !important;
	outline: none;
	line-height: 0;
	font-size: 0;
	color: transparent;
	padding: 0;
	cursor: pointer;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
	background-color: #cfcfcf !important;
	overflow: hidden;
}

.slick-dots li:hover button,
.slick-dots li.slick-active button {
	background-color: #3a6091 !important;
}

.slick-dots li button::before {
	content: "" !important;
}

#topRatedWrapper .slick-next,
#topRatedWrapper .slick-prev {
	background-color: transparent;
	color: #fff;
	width: 36px;
	height: 55px;
	opacity: .75
}

#topRatedWrapper .slick-prev {
	left: -30px;
	background-image: url(https://turbifycdn.com/ty/cdn/sportsawards/tc-toprated-left.png)
}

#topRatedWrapper .slick-next {
	right: -30px;
	background-image: url(https://turbifycdn.com/ty/cdn/sportsawards/tc-toprated-right.png)
}

#topRatedWrapper {
	height: 388px;
	overflow-y: hidden;
	clear: both;
	background-color: #ddeaf0;
	padding: 10px 0;
	margin-bottom: 20px;
	width: 980px
}

.topRatedSlider {
	width: 900px;
	margin-top: 10px
}

#topRatedWrapper .item {
	background-color: #fff;
	border: 1px solid #eee;
	font-family: roboto;
	margin: 0 10px;
	padding: 10px;
	width: 280px;
	font-size: 12px
}

#footer2015,
#footerUtils {
	background-color: #f8f8f8
}

#footerCopyright,
#footerSocial,
#footerUtils,
#newsletterSignupBar,
.assurances {
	margin: auto;
	width: 980px
}

#topRatedWrapper .item a {
	text-decoration: none;
	color: #4e4f52;
	font-size: 15px
}

#kickers {
	display: block;
	box-sizing: border-box;
	width: 100%;
}

#kickers a {
	display: block;
	box-sizing: border-box;
	float: left;
	height: 50px;
	width: 25%;
	padding: 0 1px;
	text-align: center;
}

#kickers a img {
	display: block;
	max-width: 100%;
	height: auto;
	margin: 0px auto;
}

#homeFooter {
	background-image: url(https://turbifycdn.com/ty/cdn/sportsawards/tc-home-footer-back.gif);
	background-repeat: no-repeat;
	color: #67686d;
	font-family: roboto;
	font-size: 12px;
	line-height: 21px;
	margin-bottom: 20px;
	margin-top: 20px;
	min-height: 273px;
	padding-left: 250px;
	text-align: left
}

#footer2015 {
	background-image: url(https://turbifycdn.com/ty/cdn/sportsawards/tc-assurances-back.png);
	background-repeat: repeat-x
}

.assurances img {
	border: 0;
	display: block
}

#footerUtils td,
#footerUtils td a {
	color: #7e7f84;
	font-family: roboto;
	font-size: 14px;
	text-decoration: none
}

#footerUtils td a:hover {
	text-decoration: underline
}

#footerUtils h3 {
	font-family: roboto condensed;
	font-weight: 400;
	font-size: 20px
}

#footerUtils {
	padding-top: 24px
}

#footerSocial {
	margin-top: 45px;
	margin-bottom: 22px
}

#footerCopyright {
	font-family: roboto;
	font-size: 13px;
	color: #7e7f84
}

#contentsTable .section {
	border: 1px solid #e0dddd;
	margin-bottom: 20px;
	padding-bottom: 0
}

#contentsTable .textBlock {
	background-color: #f8f6f6;
	padding: 20px 0;
	margin-top: 20px;
	border-top: 1px solid #e0dddd
}

#megamenuContainer,
#shopByChoices a:hover {
	background-color: #376092
}

.design2015 .newContentsDiv {
	width: auto;
}

h1#cat-title-2015,
h2#cat-title-2015 {
	background-image: url(https://turbifycdn.com/ty/cdn/sportsawards/tc-hero-bg.png);
	color: #1d3855;
	font-family: "roboto condensed", arial;
	font-size: 34px;
	font-weight: 400;
	height: 78px;
	padding-left: 53px;
	padding-top: 30px;
	text-align: left
}

h1 img,
h2#cat-title-2015 img {
	position: absolute;
	top: 0;
	left: 0;
	height: 80px;
	max-width: 100%;
	z-index: -1
}

#leftNavCell {
	background-color: #f5f5f5;
	float: left;
	font-family: roboto;
	font-size: 13px;
	padding: 4px;
	text-align: left;
	width: 184px;
}

#pageBodyCell {
	float: right;
	width: 787px
}

#leftNavCell #navbar label a:link,
#leftNavCell #navbar label a:visited {
	color: #003f68;
	display: block;
	font-family: roboto, arial;
	font-size: 13px;
	font-weight: 700;
	margin-top: 4px;
	text-decoration: none
}

#leftNavCell #navbar .subsections a:link,
#leftNavCell #navbar .subsections a:visited {
	color: #003f68;
	display: block;
	font-family: roboto, arial;
	font-size: 13px;
	margin-left: 10px;
	text-decoration: none
}

#shopByDropDown,
#tc2015-header #ysw_search_box_id {
	box-sizing: border-box;
	display: inline-block;
	line-height: 30px;
	height: 30px;
	position: absolute
}

#leftNavCell strong {
	color: #4a91cd;
	font-size: 16px
}

#tc2015-header-container {
	margin: auto
}

#tc2015-header {
	margin: auto;
	width: 980px;
	height: 94px
}

#topAssuranceContainer {
	background-image: url(https://turbifycdn.com/ty/cdn/sportsawards/top-assurance-back.png)
}

#tc2015-top-links {
	color: #7e7f84;
	font-family: roboto, arial;
	font-size: 12px;
	position: absolute;
	right: 0;
	top: 10px
}

#shopByChoices a,
#shopByChoices div.title,
#shopByDropDown {
	font-family: arial;
	text-decoration: none
}

#tc2015-top-links a {
	color: #7e7f84;
	margin: 0 8px;
	text-decoration: none
}

#tc2015-top-links a:hover {
	text-decoration: underline
}

#tc2015-logolink {
	top: 9px
}

#tc2015-satisfaction {
	position: relative;
	left: 15px
}

#tc2015-header #topsearchform {
	background-image: url(https://turbifycdn.com/ty/cdn/sportsawards/tc2016-search-back.png);
	height: 30px;
	right: 0;
	position: absolute;
	top: 50px;
	width: 290px;
	box-sizing: border-box
}

#shopByDropDown {
	background-image: url(https://turbifycdn.com/ty/cdn/sportsawards/tc2016-shop-by-back.png);
	color: #376092;
	font-size: 14px;
	padding-left: 10px;
	padding-top: 0;
	right: 305px;
	top: 50px;
	width: 325px
}

#shopByChoices {
	background-color: #fff;
	border: 2px solid #376092;
	box-sizing: border-box;
	display: none;
	padding: 0;
	position: absolute;
	right: 0;
	top: 82px;
	width: 100%;
	z-index: 10000
}

#shopByChoices a {
	color: #376092;
	display: block;
	font-size: 13px;
	padding: 4px
}

#shopByChoices a:hover {
	color: #fff
}

#tc2015-header #ysw_search_box_id {
	background: none;
	border: 0;
	font-family: arial;
	font-size: 14px;
	left: 9px;
	font-weight: 700;
	top: 0;
	opacity: 1;
	color: #262626;
	width: 244px
}

#searchGo {
	height: 28px !important;
	right: 0;
	position: absolute;
	top: 0;
	width: 28px
}

#footerUtils .post>a {
	color: #386695;
	font-size: 16px
}

#footerUtils .post>a:hover {
	color: #4f8c1e
}

.snip {
	color: #7e7f84;
	font-size: 13px
}

.post {
	margin-bottom: 1em
}

.ysw-paging-table .ysw-links {
	margin-top: 20px
}

.ysw-links {
	margin: 0;
	padding: 0;
	background-color: #f5f5f5
}

.ysw-links li {
	margin: 0 0 0 10px;
	padding: 2px;
	list-style: none
}

.ysw-links a {
	color: #003f68;
	font-family: roboto;
	font-weight: 700;
	text-decoration: none;
	font-size: 12px
}

.ysw-links .title {
	background-color: #4f7fb0;
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	padding: 10px;
	margin: 20px 0 0;
	font-family: roboto
}

.ysw-links .title:first-of-type {
	margin-top: 0
}

.ysw-links li:last-of-type {
	padding-bottom: 20px
}

.section .textBlock {
	margin-top: 10px
}

#mobileScrollToTop {
	cursor: pointer;
	display: none;
	right: 0;
	position: fixed;
	text-align: center;
	bottom: 0;
	z-index: 9999;
	max-width: 60px;
	height: auto
}

#engraving label {
	display: block;
	padding-left: 20px
}

#engraving label input[type=radio] {
	left: -5px;
	position: absolute;
	top: 0
}

.grayedOut {
	opacity: .2
}

#cancelEngrave {
	margin-top: 1em
}

#cartCounter {
	font-size: 14px;
	color: gray
}

#quantityPricing,
#quantityPricingButton {
	display: none
}

#quantityPricing table {
	width: 600px
}

#quantityPricing h2 {
	color: #376092;
	font-family: roboto;
	font-size: 20px;
	margin: 30px 0
}

#qty-pricing {
	margin-bottom: 30px;
	padding: 0 0 10px
}

@supports (overflow:-webkit-marquee) and (justify-content:inherit) {
	#tc2015-header #ysw_search_box_id {
		color: #191919;
		opacity: 1 !important;
		line-height: normal !important
	}
}

#ysw_search_box_id::placeholder {
	color: #191919;
	opacity: 1 !important;
	font-family: arial;
	font-size: 14px
}

#ysw_search_box_id::-webkit-input-placeholder {
	color: #191919;
	opacity: 1 !important;
	font-family: arial;
	font-size: 14px
}

#ysw_search_box_id:-ms-input-placeholder {
	color: #191919;
	opacity: 1 !important
}

#ysw_search_box_id:-moz-placeholder {
	color: #191919;
	opacity: 1 !important;
	font-family: arial;
	font-size: 14px
}

#shopByChoices div.title {
	color: #cc0000;
	font-size: 14px;
	padding: 5px;
	font-weight: bold;
}

/* YTIMES ENGRAVING UPDATE 2018-02-08 */
#engraving.ytMod {
	display: block;
	box-sizing: border-box;
	width: 100%;
	padding: 0px 0px 15px;
}

#engraving.ytMod div.preMsg {
	display: block;
	box-sizing: border-box;
	width: 100%;
	padding: 15px 0px 0px;
	border-top: solid 5px #5b7aa2;
}

#engraving.ytMod div.mainLabel {
	display: block;
	box-sizing: border-box;
	width: 100%;
	padding: 15px 0px;
	font: bold 22px arial;
	color: #376092;
	border-top: solid 1px #e0dddd;
}

#engraving.ytMod div.topMsg {
	display: block;
	box-sizing: border-box;
	width: 100%;
	font: normal 13px arial;
	color: #67686d;
	padding: 0px 0px 10px;
}

#engraving.ytMod div.persBlock {
	display: block;
	box-sizing: border-box;
	width: 100%;
	padding: 5px;
	margin-bottom: 5px;
	background-color: #fff;
}

#engraving.ytMod div.persBlock div.persLabel {
	display: block;
	box-sizing: border-box;
	width: 100%;
	background-color: #f8f8f8;
	font: bold 15px arial;
	color: #376092;
	padding: 6px;
}

#engraving.ytMod div.persBlock div.persLabel i.persInd {
	display: inline-block;
	width: 20px;
	height: 16px;
	margin-right: 15px;
	background: url(https://turbifycdn.com/ty/cdn/sportsawards/tc-pers-label-icon.png) 0 0 no-repeat;
}

#engraving.ytMod div.persBlock div.persLabel i.persInd.ready {
	background-position: 0 -16px;
}

#engraving.ytMod div.persBlock label {
	position: relative;
	display: block;
	box-sizing: border-box;
	width: 100%;
	font: normal 12px arial;
	color: #67686d;
	padding: 7px 6px 3px 30px;
	vertical-align: middle;
}

#engraving.ytMod div.persBlock input {
	font-weight: normal;
	position: static;
	vertical-align: middle;
}

#engraving.ytMod div.persBlock input[type=radio]:not(.formatting) {
	position: absolute;
	top: 5px;
	left: 6px;
	margin: 0;
	display: inline-block !important;
	vertical-align: middle;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	width: 17px;
	height: 17px;
	overflow: hidden;
	padding: 0px !important;
	background: url(https://turbifycdn.com/ty/cdn/sportsawards/tc-pers-radio-icon.png) 0 0 no-repeat;
}

#engraving.ytMod div.persBlock input[type=radio]:checked {
	background-position: 0 -17px;
}

#engraving.ytMod div.persBlock input[type=checkbox]:not(.formatting) {
	position: absolute;
	top: 5px;
	left: 6px;
	margin: 0;
	display: inline-block !important;
	vertical-align: middle;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	width: 17px;
	height: 17px;
	overflow: hidden;
	padding: 0px !important;
	background: url(https://turbifycdn.com/ty/cdn/sportsawards/tc-pers-checkbox-icon.png) 0 0 no-repeat;
}

#engraving.ytMod div.persBlock input[type=checkbox]:checked {
	background-position: 0 -17px;
}

#engraving.ytMod div.persBlock label b {
	color: #000;
	font-size: 13px;
}

#engraving.ytMod div.persBlock label i {
	color: #008000;
}

#engraving.ytMod div.persBlock span.persEdit {
	display: block;
	box-sizing: border-box;
	width: 100%;
	margin-top: 6px;
	padding-left: 47px;
	background: url(https://turbifycdn.com/ty/cdn/sportsawards/tc-pers-edit-icon.png) left 30px center no-repeat;
	font: normal 12px arial;
	color: #f00;
	text-decoration: underline;
	cursor: pointer;
}

#engraving.ytMod div#logopers {
	display: block;
	box-sizing: border-box;
	width: 100%;
}

div.persExtMsg {
	display: block;
	box-sizing: border-box;
	width: 100%;
	padding: 6px 0px;
	font: normal 13px arial;
	color: #67686d;
}

#engraving.ytMod #perslinesCont {}

#engraving.ytMod #perslinesCont div.persBlock.persLines {
	position: relative;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLabel {
	display: block;
	box-sizing: border-box;
	width: 100%;
	background-color: #f8f8f8;
	font: bold 16px arial;
	color: #f00;
	padding: 6px 12px;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persExampText {
	display: block;
	box-sizing: border-box;
	width: calc(100% - 200px);
	font: italic 13px arial;
	color: #67686d;
	padding: 15px 0;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine {
	display: block;
	box-sizing: border-box;
	width: 100%;
	padding: 8px 0px;
	border-bottom: solid 2px #eee;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine.examp {
	width: calc(100% - 200px);
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine input[type="text"] {
	display: block;
	float: left;
	box-sizing: border-box;
	width: calc(100% - 250px);
	border: solid 1px #bababa;
	padding: 3px 5px;
	margin-bottom: 5px;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine.examp input[type="text"] {
	width: calc(100% - 70px);
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine input.formatting[type="radio"] {
	position: static;
	margin: 0 10px 0 0;
	display: inline-block !important;
	vertical-align: middle;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	width: 17px;
	height: 17px;
	overflow: hidden;
	padding: 0px !important;
	background: url(https://turbifycdn.com/ty/cdn/sportsawards/tc-pers-radio-icon.png) 0 0 no-repeat;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine input.formatting[type="radio"]:checked {
	background-position: 0 -17px;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine input.formatting[type="checkbox"] {
	position: static;
	margin: 0 10px 0 0;
	display: inline-block !important;
	vertical-align: middle;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	width: 17px;
	height: 17px;
	overflow: hidden;
	padding: 0px !important;
	background: url(https://turbifycdn.com/ty/cdn/sportsawards/tc-pers-checkbox-icon.png) 0 0 no-repeat;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine input.formatting[type="checkbox"]:checked {
	background-position: 0 -17px;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine select.formatting {
	border: solid 1px #bababa;
	padding: 3px 5px;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine span.inpLabel.linetext {
	clear: both;
	display: block;
	box-sizing: border-box;
	width: 60px;
	padding: 4px 10px 0 0;
	float: left;
	margin-bottom: 5px;
	font-weight: bold;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine div.persAlign {
	display: block;
	box-sizing: border-box;
	width: 180px;
	float: left;
	text-align: center;
	padding: 0px 0px 5px 10px;
	font-size: 11px;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine div.persAlign input.formatting[type="radio"] {
	margin: 0 3px 0 0;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine div.persAlign span.radioLabel {
	padding-right: 10px;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine div.formatBlock {
	display: inline-block;
	padding: 0px 10px 0px 0px;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine div.formatBlock span.inpLabel {
	display: inline-block;
	width: 50px;
	padding: 0 10px 0 0;
	font-size: 11px;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine div.formatBlock input.formatting[type="checkbox"] {
	margin: 0 3px 0 0;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persLine div.formatBlock span.radioLabel {
	font-size: 11px;
	padding-right: 10px;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persExamp {
	position: absolute;
	top: 70px;
	right: 0px;
	width: 180px;
}

#engraving.ytMod #perslinesCont div.persBlock.persLines div.persExamp img {
	display: block;
	margin: 0px 0px 15px;
	max-width: 100%;
}

#engraving.ytMod #perslinesCont div.persExtMsg {}

#engraving.ytMod #perslinesCont div.persExtMsg input[type="radio"] {
	position: static;
	margin: 0 10px 0 0;
	display: inline-block !important;
	vertical-align: middle;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	width: 17px;
	height: 17px;
	overflow: hidden;
	padding: 0px !important;
	background: url(https://turbifycdn.com/ty/cdn/sportsawards/tc-pers-radio-icon.png) 0 0 no-repeat;
}

#engraving.ytMod #perslinesCont div.persExtMsg input[type="radio"]:checked {
	background-position: 0 -17px;
}

#engraving.ytMod #perslinesCont div.persPreview {}

#engraving.ytMod #perslinesCont div.persPreview span.add2cartborder {}

#engraving.ytMod #perslinesCont div.persPreview span.add2cartborder input#previewPersButton {}

#engraving.ytMod #perslinesCont div.persPreview #preview {}

#engraving.ytMod #perslinesCont div.persPreview #previewbottom {}

#engraving.ytMod #perslinesCont div#spellchkbtn {}

#engraving.ytMod #perslinesCont div#spellchkbtn span.add2cartborder {}

#engraving.ytMod #perslinesCont div#spellchkbtn span.add2cartborder input#spellCheckBtn {}

#engraving.ytMod #perslinesCont div#spellchkbtn #spellCheck {}

#engraving.ytMod #perslinesCont div#spellchkbtn small {}

#engraving.ytMod #perslinesCont div.persBlock.finalBlock {}

#engraving.ytMod #perslinesCont div.persBlock.finalBlock input[type="checkbox"] {}

#engraving.ytMod #perslinesCont div.persBlock.finalBlock div#divProof {}

#engraving.ytMod #perslinesCont div.persBlock.finalBlock label input[type="checkbox"] {}

#engraving.ytMod #cancelEngrave {
	display: block;
	box-sizing: border-box;
	padding: 5px 15px;
	max-width: 100%;
	margin: 20px 0 0 0;
}

#ytimesMultiAdd {
	display: block;
	box-sizing: border-box;
	width: 100%;
	border-top: solid 5px #5b7aa2;
	border-bottom: solid 5px #5b7aa2;
	padding: 5px 0 0 0;
	margin-top: 10px;
}

#ytimesMultiAdd div.persBlock {
	display: block;
	box-sizing: border-box;
	width: 100%;
	padding: 5px;
	margin-bottom: 5px;
	background-color: #fff;
}

#ytimesMultiAdd div.persBlock div.persLabel {
	display: block;
	box-sizing: border-box;
	width: 100%;
	background-color: #f8f8f8;
	font: bold 15px arial;
	color: #376092;
	padding: 6px 12px;
	margin-bottom: 8px;
}

#ytimesMultiAdd div.persBlock div.persLabel span {
	font-weight: normal;
}

#ytimesMultiAdd div.persBlock label {
	position: relative;
	display: block;
	box-sizing: border-box;
	width: 100%;
	font: normal 13px arial;
	color: #67686d;
	padding: 7px 6px 3px 30px;
	vertical-align: middle;
}

#ytimesMultiAdd div.persBlock input[type=checkbox] {
	position: absolute;
	top: 5px;
	left: 6px;
	margin: 0;
	display: inline-block !important;
	vertical-align: middle;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	width: 17px;
	height: 17px;
	overflow: hidden;
	padding: 0px !important;
	background: url(https://turbifycdn.com/ty/cdn/sportsawards/tc-pers-checkbox-icon.png) 0 0 no-repeat;
}

#ytimesMultiAdd div.persBlock input[type=checkbox]:checked {
	background-position: 0 -17px;
}

#ytimesMultiAdd div.persBlock label b {
	color: #222;
	font-size: 14px;
}

#ytimesMultiAdd div.persBlock label i {
	color: #008000;
}

#ytimesMultiAdd div.persCaption {
	display: block;
	box-sizing: border-box;
	width: 100%;
	font: normal 13px/18px arial;
	color: #67686d;
	padding: 8px 0px 0px 30px;
}

#ytimesMultiAdd div.persOptions {
	display: block;
	box-sizing: border-box;
	width: 100%;
	font: normal 13px arial;
	color: #67686d;
	padding: 8px 0px 0px 30px;
}

.accordionBody.closed {
	display: none;
}

.accordionHead {
	padding: 5px;
	background-color: #6885a8;
	margin: 5px 0;
	color: white;
	cursor: pointer;
	font-weight: bold;
}

.accordionHead.closed::before {
	content: "+ ";
}

.accordionHead.open::before {
	content: "- ";
}

.notSelected {
	border: 1px solid red;
}

.accordionHead .accCloser {
	float: right;
	font-weight: normal;
}

.accordionHead.closed .accCloser {
	display: none;
}

/*ysw*/

div#item2015Left table:nth-of-type(2) tr>td {
	width: 50%;
}

.addthis_toolbox.addthis_default_style.addthis_24x24_style {
	width: 150px;
	margin: auto;
	text-align: center;
}


div#c2e {
	text-align: center;
}

.ysw-paging-table {
	min-height: 800px;
}

#item2015Right #caption2015.truncate {
	height: 68px !important;
}

/* new left nav styling */
#leftNavCell {
	padding: 0;
	background-color: white;
	border: 1px solid #e8e8e8;
}

.ysw-links {
	background-color: inherit;
}

.ysw-links li {
	margin: 0;
	padding: 0px;
}

.ysw-links a {
	display: block;
	padding: 10px 20px;
	border-bottom: 1px solid #e8e8e8;
}

.ysw-links li:hover {
	background-color: #e8e8e8;
}

.ysw-links li:hover a {
	color: #ed8000;
}

@meida only screen and (max-width: 1024px) {
	#heroPlusKickers {
		width: 980px
	}

	#mainContainer {

		width: 980px;
	}

	#footerCopyright,
	#footerSocial,
	#footerUtils,
	#newsletterSignupBar,
	.assurances {
		margin: auto;
		width: 980px;
	}

	div#ytMegaMenu {
		width: 980px;
		position: relative;
		z-index: 8000;
		margin: Auto;
	}

}

@media only screen and (min-width: 1024px) {
	.imLabel {

		text-align: center !important;
	}

	#heroPlusKickers {
		width: 980px
	}

	#mainContainer {

		width: 1024px;
	}

	#footerCopyright,
	#footerSocial,
	#footerUtils,
	#newsletterSignupBar,
	.assurances {
		margin: auto;
		width: 1024px;
	}

	div#ytMegaMenu {
		width: 1024px !important;
		position: relative;
		z-index: 8000;
		margin: Auto;
	}


	#leftNavCell {
		width: 250px;
	}

	#pageBodyCell {

		width: calc(100% - 260px);
	}

	#item2015Left {
		width: 40% !important;
	}

	#item2015Right {
		width: 60% !important;
	}

	#item2015Right h5 {
		background-color: #376092
	}

	#leftNavCell #ysw-paging-filters {
		width: auto !important;
	}

	#ysw-paging-filters {
		min-width: 250px;
	}

	#ysw-paging {
		width: 100% !important;
	}

}

@media only screen and (min-width: 1366px) {
	.ysw-item-block .img {
		width: 33% !important;
	}

	#mainContainer {

		width: 1280px;
	}

	#footerCopyright,
	#footerSocial,
	#footerUtils,
	#newsletterSignupBar,
	.assurances {
		margin: auto;
		width: 1280px;
	}

	div#ytMegaMenu {
		width: 1280px !important;
		position: relative;
		z-index: 8000;
		margin: Auto;
	}

	.sections .section {
		/* width: 23% !important; */
		margin: 20px;
	}

	.sections div.section {
		/* border: 1px solid blue !important; */
		margin: 10px;
	}

	#leftNavCell {
		width: 250px;
	}

	#pageBodyCell {

		width: calc(100% - 250px);
	}

	#item2015Left {
		width: 40% !important;
	}

	#item2015Right {
		width: 60% !important;
	}

	#item2015Right h5 {
		background-color: #376092
	}

	#ysw-paging {
		width: 100%;

	}


}